import { Box, Grid, Typography } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Links from '@material-ui/core/Link'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { generateTrackingLink } from '../../utils/brandsHelper'
import { useAppState } from '../appState'
import birdAvatar from '../assets/images/bird-avatar.png'
import birdFeature from '../assets/images/bird-feature.png'
import axios from '../axios'
import CHRFloatImageLeft from '../components/floatImageLeft'
import CHRFloatImageLeftDesign from '../components/floatImageLeftDesign'
import FaqsFeaturedBrandsTab from '../components/helpPageTabs/faqsFeaturedBrands'
import ItemsPerPageFilter from '../components/ItemsPerPageFilter'
import ItemsPerPageFilterDesign from '../components/ItemsPerPageFilterDesign'
import JoinCircleDesign from '../components/joinCircleDesign'
import CHROutlinedButton from '../components/outlinedButton'
import SEO from '../components/seo'
import Spinner from '../components/spinner'
import Card from '../components/trendingCard'
import { ENDPOINTS, FONTS, ROUTES } from '../constants'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/featuredBrandsStyles'
import CHRArrowButton from './../components/arrowButton'
import NotFound from './404'
const PAGE_SIZE = 48

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const BrandListingPage = ({ t, brandId, data }) => {
  const classes = styles()

  const { search } = useLocation()
  const { page, size, sort } = parse(search)

  const [appState] = useAppState()

  const [brand, setBrand] = useState()
  const [brandName, setBrandName] = useState('')
  const [loading, setLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(Number(page) || 1)
  const [pageSize, setPageSize] = useState(Number(size) || PAGE_SIZE)
  const [sorting, setSorting] = useState(sort || 'trending')
  const [productsCount, setProductsCount] = useState(null)
  const [products, setProducts] = useState([])
  const [readMore, setReadMore] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [relatedSearchTerms, setRelatedSearchTerms] = useState(null)

  const [error, setError] = useState('')
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  const MAX_TEXT_LENGTH = isPhone ? 75 : isTablet ? 150 : 234 // to ensure that text lines match the design and show read more button
  const [categoryOptions, setCategoryOptions] = useState({})
  const [seoReadMore, setSeoReadMore] = useState(false)
  const [seoAboutReadMore, setSeoAboutReadMore] = useState(false)
  const MAX_TEXT = isPhone ? 160 : isTablet ? 195 : 485 // to ensure that text lines match the design and show read more button

  const sortingOptions = {
    trending: {
      value: 'sort=trending',
      type: 'trending',
      label: t('memberHome.trending'),
    },
    highestToLowest: {
      value: 'sort=price&dir=desc',
      type: 'highestToLowest',
      label: t('shared.highestToLowest'),
    },
    lowestToHighest: {
      value: 'sort=price&dir=asc',
      type: 'lowestToHighest',
      label: t('shared.lowestToHighest'),
    },
  }

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setProducts(items => {
      const i = items.findIndex(e => e.product_id === productId)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          shoppingBoardId,
        },
        ...items.slice(i + 1),
      ]
    })
  }

  const removeProduct = (productId: string) => {
    setProducts(items => {
      return items.filter(e => e.product_id !== productId)
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get(
          ENDPOINTS.brandById.replace('brandId', brandId)
        )
        const brand = res.data.data
        if (brand) {
          setBrand(brand)
          setBrandName(brand?.brandName.toLowerCase().replaceAll(' ', ''))
          setSeoReadMore(
            t(
              'seo.' +
                brand?.brandName.toLowerCase().replaceAll(' ', '') +
                '.headingDescription'
            ).length >= MAX_TEXT
          )
          let aboutReadMore = 0
          aboutReadMore += t(
            'seo.' +
              brand?.brandName.toLowerCase().replaceAll(' ', '') +
              '.subHeadingDescription'
          ).length
          aboutReadMore += t(
            'seo.' +
              brand.brandName.toLowerCase().replaceAll(' ', '') +
              '.point1'
          ).length
          aboutReadMore += t(
            'seo.' +
              brand.brandName.toLowerCase().replaceAll(' ', '') +
              '.point2'
          ).length
          aboutReadMore += t(
            'seo.' +
              brand.brandName.toLowerCase().replaceAll(' ', '') +
              '.point3'
          ).length
          setSeoAboutReadMore(aboutReadMore >= MAX_TEXT)

          setReadMore(brand.description?.length >= MAX_TEXT_LENGTH)
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [brandId])

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.categories)
        setCategoryOptions(data)
      } catch (err) {}
    })()
  }, [])

  useEffect(() => {
    // Extract Related Searches
    const brandCategory = brand?.category.split(',')
    if (brandCategory?.length) {
      let relatedSearches = []
      for (const [key, value] of Object.entries(categoryOptions)) {
        if (brandCategory.includes(key)) {
          relatedSearches.push({
            link: `/category/${key.toLowerCase()}`,
            label: key.toLowerCase(),
          })
          value.forEach((sub: any) => {
            if (sub.name.toLowerCase().trim() != 'tops') {
              relatedSearches.push({
                link: `/category/${key.toLowerCase()}_${sub.name
                  .toLowerCase()
                  .replaceAll(' ', '%20')}`,
                label: sub.name.toLowerCase(),
              })
            }
          })
        }
      }
      if (relatedSearches.length > 10) {
        relatedSearches = relatedSearches
          .sort(() => 0.5 - Math.random())
          .slice(0, 12)
      }
      setRelatedSearchTerms(relatedSearches)
    }
  }, [categoryOptions, brand?.category])

  useEffect(() => {
    ;(async () => {
      try {
        setPageNumber(Number(page) || 1)
        setSorting(sort || 'trending')
        setPageSize(Number(size) || PAGE_SIZE)
        setLoadingProducts(true)

        const endpoint = `${ENDPOINTS.brandProducts.replace(
          'brandId',
          brandId
        )}?size=${pageSize}&offset=${pageSize * (pageNumber - 1)}&${
          sortingOptions[sorting].value
        }`

        const res = await axios.get(endpoint)
        if (res.data.data) {
          setProducts(res.data.data.results)
          setProductsCount(res.data.data.total)
        }
        setLoadingProducts(false)
      } catch (err) {
        setLoadingProducts(false)
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [brandId])

  const fetchNextPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)
      const offset = pageSize * pageNumber
      const endpoint = `${ENDPOINTS.brandProducts.replace(
        'brandId',
        brandId
      )}?size=${pageSize}&offset=${offset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber + 1}&size=${pageSize}`
      )

      const res = await axios.get(endpoint)

      setProducts(res.data.data.results)
      setPageNumber((p: number) => p + 1)
      setLoadingProducts(false)
    } catch (err) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const fetchPreviousPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)
      const offset = pageSize * (pageNumber - 2)
      const endpoint = `${ENDPOINTS.brandProducts.replace(
        'brandId',
        brandId
      )}?size=${pageSize}&offset=${offset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber - 1}&size=${pageSize}`
      )

      const res = await axios.get(endpoint)

      setProducts(res.data.data.results)
      setPageNumber((p: number) => p - 1)
      setLoadingProducts(false)
    } catch (err) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleSorting = async ({ type, value }) => {
    try {
      setLoadingProducts(true)
      setSorting(type)
      const endpoint = `${ENDPOINTS.brandProducts.replace(
        'brandId',
        brandId
      )}?size=${pageSize}&offset=0&${value}`
      window.history.pushState('', '', `?sort=${type}&page=1&size=${pageSize}`)
      const res = await axios.get(endpoint)
      setProducts(res.data.data.results)
      setPageNumber(1)
      setLoadingProducts(false)
    } catch (err) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handlePageSizeChange = async (newSize: number) => {
    try {
      setLoadingProducts(true)
      const totalSkippedItems = pageNumber * pageSize
      const newPageNumber = Math.ceil(totalSkippedItems / newSize)
      setPageNumber(newPageNumber)
      setPageSize(newSize)
      const endpoint = `${ENDPOINTS.brandProducts.replace(
        'brandId',
        brandId
      )}?size=${newSize}&offset=${newSize * (newPageNumber - 1)}&${
        sortingOptions[sorting].value
      }`
      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${newPageNumber}&size=${newSize}`
      )
      const res = await axios.get(endpoint)
      setProducts(res.data.data.results)
      setLoadingProducts(false)
    } catch (err) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <NotFound error={error} />
  }

  if (!brand) {
    return <NotFound />
  }

  if (!brand.hasFeaturedPage) {
    return <NotFound />
  }

  const aboutBrand = isPhone ? 2 : isTablet ? 3 : 2
  const whyLoveBrand = 14

  const isReadMore = brand.description?.length >= MAX_TEXT_LENGTH
  const isSeoReadMore =
    t('seo.' + brandName + '.headingDescription').length >= MAX_TEXT
  const isSeoAboutReadMore =
    t('seo.' + brandName + '.subHeadingDescription').length +
      t('seo.' + brandName + '.point1').length +
      t('seo.' + brandName + '.point2').length +
      t('seo.' + brandName + '.point3').length >=
    MAX_TEXT

  return (
    <Layout>
      <SEO
        title={t('seo.featuredBrands.title')
          .replace(
            '[Brand]',
            brand?.brandName.charAt(0).toUpperCase() + brand?.brandName.slice(1)
          )
          .replace('[max %]', brand?.commission)}
        description={t('seo.featuredBrands.description').replace(
          '[Brand]',
          brand?.brandName.charAt(0).toUpperCase() + brand?.brandName.slice(1)
        )}
      />
      {/*Heading*/}
      <div className={classes.headingWrapper}>
        <div className={classes.headingContainer}>
          <div className={classes.headingTextContainer}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.brands)
                }}
              >
                brand
              </Links>
              <Typography color="textPrimary">
                {brand?.brandName.toLowerCase()}
              </Typography>
            </Breadcrumbs>
            <div className={classes.headingLogoContainer}>
              <img
                className={classes.headingLogoImage}
                width={100}
                height={100}
                src={brand.logo || birdAvatar}
                alt="brand logo"
              />
              <div className={classes.headingLogoText}>
                <span
                  className={classes.brandName}
                  style={{
                    marginBottom: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {brand.brandName}
                </span>
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.seoHeading}
                >
                  {brand?.brandName.toLowerCase() === 'revolve'
                    ? t('seo.featuredBrands.h1Conditional').replace(
                        '[Brand]',
                        brand?.brandName
                      )
                    : t('seo.featuredBrands.h1', {
                        Brand: brand?.brandName,
                        max: brand?.commission,
                        customText: brand?.customText || 'cash back',
                      })}
                </Typography>
                {/* <Typography
                  component="h1"
                  className={classes.brandName}
                  style={{
                    marginBottom: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  variant="h1"
                >
                  {brand.brandName}
                </Typography> */}
              </div>
            </div>
            <Typography
              component="p"
              className={classes.brandDescription}
              variant="body1"
            >
              {isReadMore ? (
                readMore ? (
                  <>
                    {brand.description?.slice(0, MAX_TEXT_LENGTH)}...{' '}
                    <a
                      className={classes.readMore}
                      onClick={() => setReadMore(false)}
                    >
                      read more
                    </a>
                  </>
                ) : (
                  <>
                    {brand.description}...{' '}
                    <a
                      className={classes.readMore}
                      onClick={() => setReadMore(true)}
                    >
                      read less
                    </a>
                  </>
                )
              ) : (
                brand.description
              )}
            </Typography>
          </div>
          <div className={classes.actionButtonsContainer}>
            <div className={classes.visitButtonContainer}>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: 15,
                  fontSize: 14,
                  fontFamily: FONTS.Graphik.GraphikLight,
                  lineHeight: '15px',
                  letterSpacing: 0,
                }}
              >
                start shopping on retailer’s site
              </div>

              <a
                className={classes.visitButton}
                href={generateTrackingLink(
                  brand,
                  {
                    userId: appState.userId,
                    userName: appState.userProfile?.userName,
                  },
                  '',
                  '',
                  Boolean(appState.userId)
                )}
                target="_blank"
              >
                visit {brand?.brandName.toLowerCase()}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/*products list*/}

      <section>
        <div className={classes.productsContainer}>
          <div className={classes.countingFilterContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
              {deviceType() !== 'mobile' && (
                <Grid item lg={3} md={4} sm={4} xs={6}>
                  <Typography
                    component="p"
                    className={classes.itemsCount}
                    variant="body1"
                  >
                    {productsCount}
                    {productsCount >= 10000 ? '+' : ''} {t('shared.items')}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                lg={5}
                md={4}
                sm={8}
                xs={deviceType() !== 'mobile' ? 6 : 12}
                style={{
                  padding: 0,
                }}
              >
                <div className={classes.filterBar}>
                  <ItemsPerPageFilterDesign
                    label={t('shared.sortBy')}
                    onFilterChange={handleSorting}
                    selectedFilter={sortingOptions[sorting]}
                    options={Object.values(sortingOptions)}
                  />
                  <ItemsPerPageFilter
                    onFilterChange={handlePageSizeChange}
                    selectedFilter={pageSize}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            alignItems="stretch"
            spacing={2}
            style={{ minHeight: '5vh' }}
          >
            {loadingProducts ? (
              <Spinner />
            ) : (
              products.map((product: any, index: any) => {
                if (
                  index === aboutBrand &&
                  pageNumber === 1 &&
                  t('seo.' + brandName + '.title') == brandName
                ) {
                  return (
                    <>
                      <Grid item lg={6} md={8} sm={8} xs={12}>
                        <div
                          className={
                            classes.joinCard +
                            ' ' +
                            (seoReadMore ? classes.joinCardConditional : '')
                          }
                        >
                          <div className={classes.seoDesc}>
                            <div className={classes.seoH2Description}>
                              <Typography
                                variant="h2"
                                className={classes.seoH2}
                              >
                                {t('seo.' + brandName + '.heading')}
                              </Typography>
                              {isSeoReadMore ? (
                                seoReadMore ? (
                                  <>
                                    {t(
                                      'seo.' + brandName + '.headingDescription'
                                    )?.slice(0, MAX_TEXT)}
                                    ...{' '}
                                    <a
                                      className={classes.readMore}
                                      onClick={() => setSeoReadMore(false)}
                                    >
                                      read more
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    {t(
                                      'seo.' + brandName + '.headingDescription'
                                    )}
                                    ...{' '}
                                    <a
                                      className={classes.readMore}
                                      onClick={() => setSeoReadMore(true)}
                                    >
                                      read less
                                    </a>
                                  </>
                                )
                              ) : (
                                t('seo.' + brandName + '.headingDescription')
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={6}
                        key={product.product_id}
                      >
                        <Card
                          updateProducts={updateProducts}
                          // onRemove={removeProduct}
                          customText={brand?.customText}
                          userId={appState.userId}
                          productInfo={product}
                          shareable
                          isPriceBottomOnMobile
                        />
                      </Grid>
                    </>
                  )
                }
                if (
                  index === whyLoveBrand &&
                  pageNumber === 1 &&
                  t('seo.' + brandName + '.title') == brandName
                ) {
                  return (
                    <>
                      <Grid item lg={6} md={8} sm={8} xs={12}>
                        <div
                          className={
                            classes.joinCard +
                            ' ' +
                            (seoAboutReadMore
                              ? classes.joinCardConditional
                              : '')
                          }
                        >
                          <div className={classes.seoDesc}>
                            <div className={classes.seoH2Description}>
                              <Typography
                                variant="h2"
                                className={classes.seoH2}
                              >
                                {t('seo.' + brandName + '.subHeading')}
                              </Typography>
                              {isSeoAboutReadMore ? (
                                seoAboutReadMore ? (
                                  <>
                                    {deviceType() === 'desktop' ? (
                                      <>
                                        {t(
                                          'seo.' +
                                            brandName +
                                            '.subHeadingDescription'
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {t(
                                          'seo.' +
                                            brandName +
                                            '.subHeadingDescription'
                                        )?.slice(0, 195)}
                                      </>
                                    )}
                                    {!isPhone && !isTablet && (
                                      <>
                                        <Box mt={2} />
                                        {'1) ' +
                                          t(
                                            'seo.' + brandName + '.point1'
                                          )?.slice(0, 220)}
                                      </>
                                    )}
                                    ...{' '}
                                    <a
                                      className={classes.readMore}
                                      onClick={() => setSeoAboutReadMore(false)}
                                    >
                                      read more
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    {t(
                                      'seo.' +
                                        brandName +
                                        '.subHeadingDescription'
                                    )}
                                    <Box mt={2} />
                                    {'1) ' + t('seo.' + brandName + '.point1')}
                                    <Box mt={2} />
                                    {'2) ' + t('seo.' + brandName + '.point2')}
                                    <Box mt={2} />
                                    {'3) ' + t('seo.' + brandName + '.point3')}
                                    {t('seo.' + brandName + '.point4') !==
                                    'seo.' + brandName + '.point4' ? (
                                      <>
                                        <Box mt={2} />
                                        {'4) ' +
                                          t('seo.' + brandName + '.point4')}
                                      </>
                                    ) : null}
                                    {t(
                                      'seo.' + brandName + '.subHeadingTwo'
                                    ) !==
                                    'seo.' + brandName + '.subHeadingTwo' ? (
                                      <>
                                        <Box mt={4} />
                                        <Typography
                                          variant="h2"
                                          className={classes.seoH2}
                                        >
                                          {t(
                                            'seo.' +
                                              brandName +
                                              '.subHeadingTwo'
                                          )}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {t(
                                      'seo.' + brandName + '.subHeadingTwoText'
                                    ) !==
                                    'seo.' +
                                      brandName +
                                      '.subHeadingTwoText' ? (
                                      <>
                                        <Box mt={2} />

                                        {t(
                                          'seo.' +
                                            brandName +
                                            '.subHeadingTwoText'
                                        )}
                                      </>
                                    ) : null}
                                    {t(
                                      'seo.' + brandName + '.subHeadingThree'
                                    ) !==
                                    'seo.' + brandName + '.subHeadingThree' ? (
                                      <>
                                        <Box mt={4} />
                                        <Typography
                                          variant="h2"
                                          className={classes.seoH2}
                                        >
                                          {t(
                                            'seo.' +
                                              brandName +
                                              '.subHeadingThree'
                                          )}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {t(
                                      'seo.' +
                                        brandName +
                                        '.subHeadingThreeText'
                                    ) !==
                                    'seo.' +
                                      brandName +
                                      '.subHeadingThreeText' ? (
                                      <>
                                        <Box mt={2} />

                                        {t(
                                          'seo.' +
                                            brandName +
                                            '.subHeadingThreeText'
                                        )}
                                      </>
                                    ) : null}
                                    ...{' '}
                                    <a
                                      className={classes.readMore}
                                      onClick={() => setSeoAboutReadMore(true)}
                                    >
                                      read less
                                    </a>
                                  </>
                                )
                              ) : (
                                <>
                                  {t(
                                    'seo.' +
                                      brandName +
                                      '.subHeadingDescription'
                                  )}
                                  <Box mt={2} />
                                  {'1) ' + t('seo.' + brandName + '.point1')}
                                  <Box mt={2} />
                                  {'2) ' + t('seo.' + brandName + '.point2')}
                                  <Box mt={2} />
                                  {'3) ' + t('seo.' + brandName + '.point3')}
                                  {t('seo.' + brandName + '.point4') !==
                                  'seo.' + brandName + '.point4' ? (
                                    <>
                                      <Box mt={2} />
                                      {'4) ' +
                                        t('seo.' + brandName + '.point4')}
                                    </>
                                  ) : null}

                                  {t('seo.' + brandName + '.subHeadingTwo') !==
                                  'seo.' + brandName + '.subHeadingTwo' ? (
                                    <>
                                      <Box mt={4} />
                                      <Typography
                                        variant="h2"
                                        className={classes.seoH2}
                                      >
                                        {t(
                                          'seo.' + brandName + '.subHeadingTwo'
                                        )}
                                      </Typography>
                                    </>
                                  ) : null}
                                  {t(
                                    'seo.' + brandName + '.subHeadingTwoText'
                                  ) !==
                                  'seo.' + brandName + '.subHeadingTwoText' ? (
                                    <>
                                      <Box mt={2} />
                                      {t(
                                        'seo.' +
                                          brandName +
                                          '.subHeadingTwoText'
                                      )}
                                    </>
                                  ) : null}

                                  {t(
                                    'seo.' + brandName + '.subHeadingThree'
                                  ) !==
                                  'seo.' + brandName + '.subHeadingThree' ? (
                                    <>
                                      <Box mt={4} />
                                      <Typography
                                        variant="h2"
                                        className={classes.seoH2}
                                      >
                                        {t(
                                          'seo.' +
                                            brandName +
                                            '.subHeadingThree'
                                        )}
                                      </Typography>
                                    </>
                                  ) : null}
                                  {t(
                                    'seo.' + brandName + '.subHeadingThreeText'
                                  ) !==
                                  'seo.' +
                                    brandName +
                                    '.subHeadingThreeText' ? (
                                    <>
                                      <Box mt={2} />
                                      {t(
                                        'seo.' +
                                          brandName +
                                          '.subHeadingThreeText'
                                      )}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={6}
                        key={product.product_id}
                      >
                        <Card
                          updateProducts={updateProducts}
                          // onRemove={removeProduct}
                          customText={brand?.customText}
                          userId={appState.userId}
                          productInfo={product}
                          shareable
                          isPriceBottomOnMobile
                        />
                      </Grid>
                    </>
                  )
                }
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={4}
                    xs={6}
                    key={product.product_id}
                  >
                    <Card
                      updateProducts={updateProducts}
                      // onRemove={removeProduct}
                      customText={brand?.customText}
                      userId={appState.userId}
                      productInfo={product}
                      shareable
                    />
                  </Grid>
                )
              })
            )}
          </Grid>
          <div className={classes.paginationContainer}>
            <CHRArrowButton
              isLeftArrow
              label={t('shared.previous')}
              onClick={fetchPreviousPage}
              disabled={pageNumber === 1 || !productsCount}
            />
            <Typography variant="subtitle2" component="p">
              {pageNumber}/{Math.ceil(productsCount / pageSize) || 1}
            </Typography>
            <CHRArrowButton
              label={t('shared.next')}
              onClick={fetchNextPage}
              disabled={
                pageNumber === Math.ceil(productsCount / pageSize) ||
                !productsCount
              }
            />
          </div>
        </div>
      </section>
      {/**/}

      {t('seo.' + brandName + '.title') != brandName && (
        <section style={{ marginTop: 80 }}>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                <Typography variant="h3">{t('memberHome.proTitle')}</Typography>
                <Typography variant="h2">
                  {t('memberHome.proSubtitle')}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {t('memberHome.proText')}
                </Typography>
                <div>
                  <div style={{ float: 'right' }}>
                    <CHRArrowButton
                      label={t('memberHome.learnMore')}
                      onClick={() => navigate(ROUTES.chirpyestPro)}
                    />
                  </div>
                </div>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[0].node.fluid}
            withoutButton
          />
        </section>
      )}

      {t('seo.' + brandName + '.title') == brandName && (
        <>
          <section style={{ marginTop: 80 }}>
            <CHRFloatImageLeftDesign
              rightChildren={
                <div>
                  <Typography variant="h3" className={classes.tipMargin}>
                    {t('memberHome.influencerTip')}
                  </Typography>
                  <Typography
                    variant="h2"
                    className={[
                      classes.headingWrap,
                      classes.headingMargin,
                    ].join(' ')}
                  >
                    {t('memberHome.browserExtension')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.lineHeight}
                  >
                    {t('memberHome.proSubtitleExt')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.lineHeight}
                  >
                    {t('memberHome.proSubtitleExt1')}
                  </Typography>
                  <div>
                    <div style={{ float: 'right' }}>
                      <CHRArrowButton
                        label={t('memberHome.getBrowserExtension')}
                        onClick={() => navigate(ROUTES.chirpyestPro)}
                      />
                    </div>
                  </div>
                </div>
              }
              isGatsbyImg={true}
              image={data.allImageSharp.edges[0].node.fluid}
              withoutButton
            />
          </section>

          <section style={{ marginTop: 80 }}>
            <div className={classes.productsContainer}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={6}
              >
                <Grid item lg={3} md={3} sm={12}>
                  {deviceType() === 'tablet' && (
                    <div style={{ display: 'flex' }}>
                      <CHROutlinedButton
                        label="join chirpyest - it's free"
                        font={FONTS.Graphik.GraphikMedium}
                        onClick={() => {
                          navigate(ROUTES.join)
                        }}
                      />
                      <Box mr={2} />
                      <CHROutlinedButton
                        label="shop 1,000's of brands"
                        font={FONTS.Graphik.GraphikMedium}
                        onClick={() => {
                          navigate(ROUTES.shop)
                        }}
                      />
                    </div>
                  )}
                  {deviceType() !== 'tablet' && (
                    <>
                      <CHROutlinedButton
                        label={
                          appState?.userId
                            ? 'share to earn $'
                            : "join chirpyest - it's free"
                        }
                        font={FONTS.Graphik.GraphikMedium}
                        onClick={() => {
                          if (appState?.userId) {
                            navigate(ROUTES.createShareLink)
                          } else {
                            navigate(ROUTES.join)
                          }
                        }}
                      />
                      <Box mt={2} />
                      <CHROutlinedButton
                        label="shop 1,000's of brands"
                        font={FONTS.Graphik.GraphikMedium}
                        onClick={() => {
                          navigate(ROUTES.brands)
                        }}
                      />
                    </>
                  )}

                  <Box mt={3} />
                  <div className={classes.chirpyestCreators}>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        width={deviceType() !== 'mobile' ? 60 : 46}
                        height={deviceType() !== 'mobile' ? 60 : 50}
                        src={birdFeature}
                        alt="brand logo"
                      />
                      <Typography
                        variant="h2"
                        className={classes.chirpyestCreatorsHeading}
                      >
                        chirpyest is for shoppers + creators
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <Typography variant="h3">
                    {t('memberHome.howChirpyestWorks') + brand.brandName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.lineHeight}
                  >
                    it is super simple to make money while you shop. just{' '}
                    <strong>join chirpyest, add our browser extension,</strong>{' '}
                    and then{' '}
                    <strong>
                      shop 1000s of retailers like {brand.brandName}
                    </strong>{' '}
                    and <strong>earn up to 30% cash back.</strong> Our browser
                    extension finds cash back for you. On mobile, tap any brand
                    our brands page when you are logged in and cash back will be
                    activated.
                  </Typography>

                  <div className={classes.firstRow}>
                    <JoinCircleDesign circlelabel="easy" color="#175972">
                      shopping tools to help find cashback
                    </JoinCircleDesign>
                    <JoinCircleDesign
                      circlelabel="share"
                      color="#F27870"
                      customStyle={classes.circle2}
                    >
                      finds with friends – earn more cash back
                    </JoinCircleDesign>
                  </div>
                  <div className={classes.secondRow}>
                    <JoinCircleDesign circlelabel="save" color="#F9BEBF">
                      items to your board
                    </JoinCircleDesign>
                    <JoinCircleDesign
                      circlelabel="earn"
                      color="#2E7C86"
                      customStyle={classes.circle4}
                    >
                      up to 30% cash back
                    </JoinCircleDesign>
                    <JoinCircleDesign circlelabel="shop" color="#E33D40">
                      1,000’s of top brands
                    </JoinCircleDesign>
                  </div>
                  <Typography variant="h3">
                    {t('memberHome.affiliateWorks') +
                      brand.brandName.toLowerCase()}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.lineHeight}
                  >
                    affiliate links let you <strong>share a product</strong> and{' '}
                    <strong> earn money </strong> if someone shops your
                    recommendation - <strong>just like top influencers</strong>.
                    Create a Chirpyest powered "
                    <span
                      className={classes.joinLinkSpan}
                      onClick={() => {
                        if (appState?.userId) {
                          navigate(ROUTES.createShareLink)
                        } else {
                          navigate(ROUTES.join)
                        }
                      }}
                    >
                      share link
                    </span>
                    " or curate your own {' "'}
                    <span
                      className={classes.joinLinkSpan}
                      onClick={() => {
                        if (appState?.userId) {
                          navigate(
                            ROUTES.mainBoard.replace(
                              ':userName',
                              appState.username
                            )
                          )
                        } else {
                          navigate(ROUTES.join)
                        }
                      }}
                    >
                      shopping board
                    </span>
                    " to share products from urban outfitters and 850+ other
                    brands with friends, family or followers and then{' '}
                    <strong>earn when they shop.</strong>{' '}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </section>

          <section style={{ marginTop: 80 }}>
            <div className={classes.productsContainer}>
              <div>
                <Typography
                  variant="h2"
                  className={classes.headingMargin}
                  style={{ marginBottom: 40 }}
                >
                  related searches
                </Typography>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12}>
                    {relatedSearchTerms?.map((term: any) => (
                      <>
                        <span
                          style={{ marginRight: 30, lineHeight: '30px' }}
                          className={classes.joinLinkSpan}
                          onClick={() => {
                            navigate(term.link)
                          }}
                        >
                          {term.label}
                        </span>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </div>
          </section>

          <section style={{ marginTop: 80 }}>
            <div className={classes.productsContainer}>
              <div>
                <Typography variant="h2" className={classes.headingMargin}>
                  iykyk… for those who frequently ask…
                </Typography>
              </div>
              <div className={classes.tabPanelSection}>
                <TabPanel value={0} index={0}>
                  <FaqsFeaturedBrandsTab
                    brand={brand?.brandName}
                    commission={brand?.commission}
                  />
                </TabPanel>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  )
}

export default withTranslation()(BrandListingPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: { fluid: { originalName: { in: ["getting-hang-of-it.png"] } } }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
